<template>
    <PlayGround></PlayGround>
</template>

<script>
import PlayGround from '../../components/PlayGround.vue';

export default {
    components: {
        PlayGround,
    }
}
</script>


<style scoped>

</style>