<template>
    <div v-if="$store.state.pk.status === 'playing' && parseInt($store.state.user.id) === parseInt($store.state.pk.b_id)" class="user-notice-blue">
        <div class="d-inline p-1 bg-primary text-white" style="border-radius: 3%;">你出生在蓝色方</div>
    </div>
    <PlayGround v-if="$store.state.pk.status === 'playing'"></PlayGround>
    <MatchGround v-if="$store.state.pk.status === 'matching'"></MatchGround>
    <ResultBoard v-if="$store.state.pk.loser !== 'none'"></ResultBoard>
    <div v-if="$store.state.pk.status === 'playing' && parseInt($store.state.user.id) === $store.state.pk.a_id" class="user-notice-red">
        <div class="d-inline p-1 bg-danger text-white" style="border-radius: 3%;">你出生在红色方</div>
    </div>
</template>


<script>
import { useStore } from 'vuex';
import PlayGround from '../../components/PlayGround.vue';
import MatchGround from '../../components/MatchGround.vue';
import ResultBoard from '../../components/ResultBoard.vue';
import { onMounted, onUnmounted } from 'vue';

export default {
    components: {
        PlayGround,
        MatchGround,
        ResultBoard
    },
    setup() {
        const store = useStore();
        const socketUrl = `wss://kob.chtld.cn/websocket/${store.state.user.token}/`;
        let socket = null;

        store.commit("updateLoser", "none");
        store.commit("updateIsRecord", false);

        onMounted(() => {
            store.commit("updateOpponent", {
                username: "我的对手",
                photo: "https://cdn.acwing.com/media/article/image/2022/08/09/1_1db2488f17-anonymous.png",
            });

            socket = new WebSocket(socketUrl);

            socket.onopen = () => {
                console.log("connected!");
                store.commit("updateSocket", socket);
            }

            socket.onmessage = (msg) => {
                const data = JSON.parse(msg.data);
                if (data.event === "start_matching") {
                    store.commit("updateOpponent", {
                        username: data.opponent_username,
                        photo: data.opponent_photo,
                    });
                    setTimeout(() => {
                        store.commit("updateStatus", "playing");
                    }, 200);
                    store.commit("updateGame", data.game);
                } else if (data.event === "move") {
                    console.log(data);
                    const gameObject = store.state.pk.gameObject;
                    const [snake0, snake1] = gameObject.snakes;
                    snake0.set_direction(data.a_direction);
                    snake1.set_direction(data.b_direction);
                } else if (data.event === "result") {
                    console.log(data);
                    const gameObject = store.state.pk.gameObject;
                    const [snake0, snake1] = gameObject.snakes;
                    console.log(gameObject);
                    if (data.loser === "all" || data.loser === "A") {
                        snake0.status = "die";
                    }
                    if (data.loser === "all" || data.loser === "B") {
                        snake1.status = "die";
                    }
                    store.commit("updateLoser", data.loser);
                }
            }

            socket.onclose = () => {
                console.log("disconnected.");
            }
        });

        onUnmounted(() => {
            socket.close();
            store.commit("updateStatus", "matching");
        });

    }
}
</script>


<style scoped>
div.user-notice-blue {
    position: absolute;
    top: 15vh;
    left: 70vw;
    text-align: center;
    font-size: 4vh;
}

div.user-notice-red {
    position: absolute;
    top: 78vh;
    left: 17vw;
    text-align: center;
    font-size: 4vh;
    border-radius: 20%;
}


/* div.user-notice-blue {
    left: 70%;
} */
</style>