<template>
    <div class="container">
        <div class="row">
            <div class="col-3">
                <div class="card" style="margin-top: 20px;">
                    <div class="card-body">
                        <img :src="$store.state.user.photo" alt="" style="width: 100%;">
                    </div>
                </div>
            </div>
            <div class="col-9">
                <div class="card" style="margin-top: 20px;">
                    <div class="card-head">
                        <span style="font-size: 120%; margin-left: 20px;">我的 Bot</span>
                        <button type="button" class="btn btn-primary float-end" data-bs-toggle="modal" data-bs-target="#add-bot">创建 Bot</button>


                        <!-- Modal -->
                        <div class="modal fade" id="add-bot" tabindex="-1">
                        <div class="modal-dialog modal-xl">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">创建 Bot</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="mb-3">
                                    <label for="add-bot-title" class="form-label">名称</label>
                                    <input v-model="botadd.title" type="text" class="form-control" id="add-bot-title" placeholder="请输入 Bot 名称">
                                </div>
                                <div class="mb-3">
                                    <label for="add-bot-description" class="form-label">简介</label>
                                    <textarea v-model="botadd.description" class="form-control" id="add-bot-description" rows="2" placeholder="请输入 Bot 简介"></textarea>
                                </div>
                                <div class="mb-3">
                                    <label for="add-bot-code" class="form-label">代码</label>
                                    <VAceEditor
                                        v-model:value="botadd.content"
                                        @init="editorInit"
                                        lang="c_cpp"
                                        theme="textmate"
                                        style="height: 300px" />
                                </div>
                            </div>
                            <div class="modal-footer">
                                <div class="error-message"> {{ botadd.error_message }} </div>
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
                                <button @click="add_bot" type="button" class="btn btn-primary">提交</button>
                            </div>
                            </div>
                        </div>
                        </div>


                    </div>
                    <div class="card-body">
                        <table class="table table-striped table-hover">
                            <thead> 
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">名称</th>
                                    <th scope="col">创建时间</th>
                                    <th scope="col">操作</th>                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(bot, i) in bots" :key="bot.id">
                                    <td>{{ i + 1 }}</td>
                                    <td>{{ bot.title }}</td>
                                    <td>{{ bot.createtime }}</td>
                                    <td>
                                        <button type="button" class="btn btn-success btn-sm" style="margin-right: 10px;" data-bs-toggle="modal" :data-bs-target="'#update-bot-modal-' + bot.id">修改</button>
                                        <button type="button" class="btn btn-danger btn-sm" @click="remove_bot(bot)">删除</button>


                                        <!-- Modal -->
                                        <div class="modal fade" :id="'update-bot-modal-' + bot.id" tabindex="-1">
                                        <div class="modal-dialog modal-xl">
                                            <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title">修改 Bot</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="mb-3">
                                                    <label for="add-bot-title" class="form-label">名称</label>
                                                    <input v-model="bot.title" type="text" class="form-control" id="add-bot-title" placeholder="请输入 Bot 名称">
                                                </div>
                                                <div class="mb-3">
                                                    <label for="add-bot-description" class="form-label">简介</label>
                                                    <textarea v-model="bot.description" class="form-control" id="add-bot-description" rows="2" placeholder="请输入 Bot 简介"></textarea>
                                                </div>
                                                <div class="mb-3">
                                                    <label for="add-bot-code" class="form-label">代码</label>
                                                    <VAceEditor
                                                        v-model:value="bot.content"
                                                        @init="editorInit"
                                                        lang="c_cpp"
                                                        theme="textmate"
                                                        style="height: 300px" />
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <div class="error-message"> {{ botadd.error_message }} </div>
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
                                                <button @click="update_bot(bot)" type="button" class="btn btn-primary">提交</button>
                                            </div>
                                            </div>
                                        </div>
                                        </div>


                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { reactive, ref } from 'vue'
import $ from 'jquery'
import { Modal } from 'bootstrap/dist/js/bootstrap.js'
import { VAceEditor } from 'vue3-ace-editor'
import ace from 'ace-builds'


export default {
    components: {
        VAceEditor,
    },
    setup() {
        ace.config.set("basePath", "https://cdn.jsdelivr.net/npm/ace-builds@" + require('ace-builds').version + "/src-noconflict/");
        const store = useStore();
        let bots = ref([]);

        const botadd = reactive({
            title: "",
            description: "",
            content: "",
            error_message: "",
        });

        const refresh_bots = () => {
            $.ajax({
                url: "https://kob.chtld.cn/api/user/bot/getlist/",
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    bots.value = resp;
                }
            });
        }

        refresh_bots();

        const add_bot = () => {
            $.ajax({
                url: "https://kob.chtld.cn/api/user/bot/add/",
                type: "post",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                data: {
                    title: botadd.title,
                    description: botadd.description,
                    content: botadd.content,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        botadd.title = "";
                        botadd.description = "";
                        botadd.content = "";
                        Modal.getInstance("#add-bot").hide();
                        refresh_bots();
                    } else {
                        botadd.error_message = resp.error_message;
                    }
                }
            });
        }

        const update_bot = (bot)=> {
            botadd.error_message = "";
            $.ajax({
                url: "https://kob.chtld.cn/api/user/bot/update/",
                type: "post",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                data: {
                    bot_id: bot.id,
                    title: bot.title,
                    description: bot.description,
                    content: bot.content,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        Modal.getInstance('#update-bot-modal-' + bot.id).hide();
                        refresh_bots();
                    } else {
                        bot.error_message = resp.error_message;
                    }
                }
            });
        }

        const remove_bot = (bot) => {
            $.ajax({
                url: "https://kob.chtld.cn/api/user/bot/remove/",
                type: "post",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                data: {
                    bot_id: bot.id,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        refresh_bots();
                    }
                }
            });
        }    

        return {
            bots,
            botadd,
            add_bot,
            update_bot,
            remove_bot
        }
    }
}
</script>


<style scoped>
td {
    vertical-align: middle;
}

div.error-message {
    color: red;
}
</style>