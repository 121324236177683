export default {
  state: {
    status: "matching",
    opponent_username: "",
    opponent_photo: "",
    socket: null,
    gamemap: null,
    gameObject: null,
    a_id: 0,
    a_sx: 0,
    a_sy: 0,
    b_id: 0,
    b_sx: 0,
    b_sy: 0,
    loser: 'none',
  },
  getters: {
  },
  mutations: {
    updateStatus(state, status) {
      state.status = status;
    },
    updateOpponent(state, opponent) {
      state.opponent_username = opponent.username;
      state.opponent_photo = opponent.photo;
    },
    updateSocket(state, socket) {
      state.socket = socket;
    },
    updateGame(state, game) {
      state.gamemap = game.map;
      state.a_id = game.a_id;
      state.a_sx = game.a_sx;
      state.a_sy = game.a_sy;
      state.b_id = game.b_id;
      state.b_sx = game.b_sx;
      state.b_sy = game.b_sy;
    },
    updateGameObject(state, gameObject) {
      state.gameObject = gameObject;
    },
    updateLoser(state, loser) {
      state.loser = loser;
    }
  },
  actions: {
  },
  modules: {
  }
}
